const Menu = [
    {
        heading: 'Navegación',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        path: '/',
        icon: 'fas fa-user-tie'
    },
    {
        name: 'Viajes',
        path: '/trips',
        icon: 'fa fa-duotone fa-taxi'
    },
    {
        name: 'Ventas',
        path: '/ventas',
        icon: 'fas fa-money-bill-wave-alt'
    },
    {
        name: 'Pasajeros',
        path: '/clients',
        icon: 'fas fa-user-friends'
    },
    {
        name: 'Conductores',
        icon: '	fa fa-solid fa-id-card',
        submenu: [
            {
                name: 'Conductores',
                path: '/drivers'
            },
            {
                name: 'Documentos Aprobados',
                path: '/drivers-validated'
            },
            {
                name: 'Documentos por Validar',
                path: '/drivers-validate'
            },
        ]
    },
    {
        name: 'Pagos de conductores',
        icon: 'fa fa-solid fa-money-bill',
        submenu: [
            {
                name: 'Todos los pagos',
                path: '/payments'
            },
            {
                name: 'Pagos atrasados',
                path: '/late-payments'
            },
            {
                name: 'Pagos finalizados',
                path: '/payments-paid'
            },
            {
                name: 'Pagos pendientes',
                path: '/pending-payments'
            },
            {
                name: 'Pagos vencidos',
                path: '/past-due-payments'
            }
        ]
    },
    {
        name: 'Incidencias',
        path: '/incidencias',
        icon: 'fas fa-exclamation-triangle'
    },
    {
        name: 'Penalizaciones',
        icon: 'fas fa-flag',
        submenu: [
            {
                name: 'Choferes',
                path: '/driver-penalties'
            },
            {
                name: 'Pasajeros',
                path: '/passenger-penalties'
            }
        ]
    },
    {
        name: 'Notificaciones',
        path: '/notifications',
        icon: 'fas fa-paper-plane'
    },
    {
        name: 'Administración',
        icon: 'fas fa-user-shield',
        submenu: [
            {
                name: 'Servicios',
                path: '/services'
            },
            {
                name: 'Planes',
                path: '/planes'
            },
            {
                name: 'Ajustes',
                path: '/settings'
            },
            {
                name: 'Emails',
                path: '/emails'
            },
            {
                name: 'Administradores',
                path: '/admins'
            }
        ]
    },
    
];

export default Menu;
