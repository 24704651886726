import axios from "axios";

// Bimo
export const ENDPOINT = "https://api.bimodrivermx.com/api/v1/";

const sessionToken = () => (
    JSON.parse(sessionStorage.getItem("USERSESSION")) ? JSON.parse(sessionStorage.getItem("USERSESSION")).accessToken : false
);


const requestFunction = async (method, url, body) => {
    const headers = sessionToken() ? { 'authorization': sessionToken(), 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' }
    const options = {
        method,
        url,
        data: JSON.stringify(body || {}),
        headers
    }
    
    try {
        const response = await axios(options);
        
        // console.log("User->", headers.authorization)
        return { error: false, data: response.data };
    } catch (error) {
        // console.log("Error", error)
        let errorMessages = [];
        const errors = error.response.message;
        for (let error in errors) {
            if (Array.isArray(errors[error])) {
                errorMessages.push(`${errors[error][0]}, `);
            } else {
                errorMessages.push(`${errors[error]}, `);
            }
        }
        throw { error: true, data: "".concat(errorMessages) }
    }
};


export const login = async ({ email, password }) => {
    return await requestFunction('post', `${ENDPOINT}signin-admin`, { email, password });
}

export const logoutSession = async () => {
    return await requestFunction('post', `${ENDPOINT}logout`);
}


// //ADMINISTRATORS
export const getAdministrators = async () => {
    return await requestFunction('get', `${ENDPOINT}admins`);
}
export const newAdministrator = async (admin) => {
    return await requestFunction('post', `${ENDPOINT}admins`, admin)
}

export const getAdministrator = async (id) => {
    return await requestFunction('get', `${ENDPOINT}admins/${id}`);
}

export const updateAdministrator = async (admin, id) => {
    return await requestFunction('put', `${ENDPOINT}admins/${id}`, admin)
}
export const deleteAdmin = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}admins/${id}`)
}

// Notificaciones
export const getNotifications = async () => {
    return await requestFunction('get', `${ENDPOINT}notifications`);
}
export const newNotification = async (notification) => {
    return await requestFunction('post', `${ENDPOINT}notifications`, notification)
}
export const getNotification = async (id) => {
    return await requestFunction('get', `${ENDPOINT}notifications/${id}`);
}
export const updateNotification = async (notification, id) => {
    return await requestFunction('put', `${ENDPOINT}notifications/${id}`, notification)
}
export const delNotification = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}notifications/${id}`)
}
export const sendNotification = async (id) => {
    return await requestFunction('post', `${ENDPOINT}send-notifications/${id}`);
}

// Pasajeros
export const getPassengers = async () => {
    return await requestFunction('get', `${ENDPOINT}passengers`);
}
export const getPassenger = async (id) => {
    return await requestFunction('get', `${ENDPOINT}passengers/${id}`);
}

//Choferes
export const getDrivers = async () => {
    return await requestFunction('get', `${ENDPOINT}drivers`);
}
export const getDriver = async (id) => {
    return await requestFunction('get', `${ENDPOINT}drivers/${id}`);
}


// Documentos
export const getDocumentDriver = async (id) => {
    return await requestFunction('get', `${ENDPOINT}documents-drivers/${id}`);
}
export const driversVerified = async () => {
    return await requestFunction('get', `${ENDPOINT}documents-drivers-verified`);
}
export const driversNoVerified = async () => {
    return await requestFunction('get', `${ENDPOINT}documents-drivers-no-verified`);
}

export const updateDocuments = async (form, id) => {
    return await requestFunction('patch', `${ENDPOINT}documents-drivers/update/${id}`, form);
}

// Ventas
export const getventa = async (id) => {
    return await requestFunction('get', `${ENDPOINT}ventas-trips/${id}`);
}

export const getAllVentas = async () => {
    return await requestFunction('get', `${ENDPOINT}ventas-trips`);
}

export const getAmountVentas = async () => {
    return await requestFunction('get', `${ENDPOINT}ventas-trips-amount`);
}

export const getTotalVentas = async () => {
    return await requestFunction('get', `${ENDPOINT}ventas-trips-total`);
}

export const getPeriodoVentas = async (period) => {
    return await requestFunction('get', `${ENDPOINT}ventas-trips-period`,period);
}

// paymentDrivers
export const getPayment = async (id) => {
    return await requestFunction('get', `${ENDPOINT}driver-pagos/${id}`);
}

export const getAllPayments = async () => {
    return await requestFunction('get', `${ENDPOINT}driver-pagos`);
}

export const getLatePayments = async () => {
    return await requestFunction('get', `${ENDPOINT}driver-pagos-atrasados`);
}

export const getPaymentsPaid = async () => {
    return await requestFunction('get', `${ENDPOINT}driver-pagos-pagados`);
}

export const getPendingPayments = async () => {
    return await requestFunction('get', `${ENDPOINT}driver-pagos-pendientes`);
}

export const getPastDuePayments = async () => {
    return await requestFunction('get', `${ENDPOINT}driver-pagos-vencidos`);
}


// Trips
export const getTrip = async (id) => {
    return await requestFunction('get', `${ENDPOINT}trips/${id}`);
}

export const getAllTrips = async () => {
    return await requestFunction('get', `${ENDPOINT}all-trips`);
}

// Servicios Bimo
export const getServicesList = async () => {
    return await requestFunction('get', `${ENDPOINT}services-bimo`);
}
export const getService = async (id) => {
    return await requestFunction('get', `${ENDPOINT}services-bimo/${id}`);
}
export const updateService = async (form, id) => {
    return await requestFunction('put', `${ENDPOINT}services-bimo/${id}`, form)
}

// Planes Bimo
export const getPlansList = async () => {
    return await requestFunction('get', `${ENDPOINT}plan-pagos`);
}
export const getPlan = async (id) => {
    return await requestFunction('get', `${ENDPOINT}plan-pagos/${id}`);
}
export const updatePlan = async (form, id) => {
    return await requestFunction('put', `${ENDPOINT}plan-pagos/${id}`, form)
}

// Emails
export const getEmails = async () => {
    return await requestFunction('get', `${ENDPOINT}emails`);
}
export const newEmail = async (email) => {
    return await requestFunction('post', `${ENDPOINT}emails`, email)
}

export const getEmail = async (id) => {
    return await requestFunction('get', `${ENDPOINT}emails/${id}`);
}

export const updateEmail = async (email, id) => {
    return await requestFunction('put', `${ENDPOINT}emails/${id}`, email)
}
export const deleteEmail = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}emails/${id}`)
}

// Incidencias
export const incidenciaIndex = async () => {
    return await requestFunction('get', `${ENDPOINT}incidentes`);
}
export const getIncidencia = async (id) => {
    return await requestFunction('get', `${ENDPOINT}incidentes/${id}`);
}

// Penalizaciones
export const penalizacionesIndex = async () => {
    return await requestFunction('get', `${ENDPOINT}penalizaciones-drivers`);
}
export const getPenalizacion = async (id) => {
    return await requestFunction('get', `${ENDPOINT}penalizaciones-drivers/${id}`);
}

// Settings
export const getSettings = async () => {
    return await requestFunction('get', `${ENDPOINT}plan-pagos`);
}
export const getSetting = async (id) => {
    return await requestFunction('get', `${ENDPOINT}plan-pagos/${id}`);
}
export const updateSetting = async (form, id) => {
    return await requestFunction('patch', `${ENDPOINT}plan-pagos/${id}`, form);
}

//Dashboard Principal
export const showDriversOnline = async () => {
    return await requestFunction('get', `${ENDPOINT}location-drivers`);
}
export const DashboardValues = async () => {
    return await requestFunction('get', `${ENDPOINT}values-app`);
} 