import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* protectedroute component para rutas protegidas*/
import { ProtectedRoute } from './auth/protected.route';
// import Child_categories from './components/Child_categories.js/child_categories';
// import Banners from './components/Banners/Banners';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import PlansList from './components/Plans/PlansList';


/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Home = lazy(() => import('./components/home/Home'));

const Administrators = lazy(() => import('./components/Administrators/Administradors'));
const AdministratorForm = lazy(() => import('./components/Administrators/AdminForm'));
// const Clients = lazy(() => import('./components/Users/Clients'));
// const ClientForm = lazy(() => import('./components/Users/ClientForm'));
const Login = lazy(() => import('./pages/Login'));

const Notifications = lazy(() => import('./components/Notifications/Notifications'));
const NotificationForm = lazy(() => import('./components/Notifications/NotificationForm.js'));

const Clients = lazy(() => import('./components/Clients/Clients'));
const ClientView = lazy(() => import('./components/Clients/ClientView'));

const Drivers = lazy(() => import('./components/Drivers/Drivers'));
const DriverView = lazy(() => import('./components/Drivers/DriverView'));

const DriversnoVerify = lazy(() => import('./components/Documents/DocsNoVerify'));
const ValidateDriver = lazy(() => import ('./components/Documents/Validate-driver'));
const DriversVerified = lazy(() => import ('./components/Documents/DocsVerified'))

const Ventas = lazy(() => import('./components/Ventas/Ventas'));
const VentasView = lazy(() => import ('./components/Ventas/VentasView'))

const Trips = lazy(() => import('./components/Trips/Trips'));
const TripView = lazy(() => import ('./components/Trips/TripView'))

const Emails = lazy(() => import('./components/Emails/Emails'));
const EmailForm = lazy(() => import ('./components/Emails/EmailForm'))

const Payments = lazy(() => import('./components/PaymentsDrivers/Payments'));
const PaymentView = lazy(() => import ('./components/PaymentsDrivers/PaymentView'))
const LatePayments = lazy(() => import('./components/PaymentsDrivers/LatePayments'));
const PastDuePayments = lazy(() => import('./components/PaymentsDrivers/PastDuePayments'));
const PaymentsPaid = lazy(() => import('./components/PaymentsDrivers/PaymentsPaid'));
const PendingPayments = lazy(() => import('./components/PaymentsDrivers/PendingPayments'));

const IncidenciasIndex = lazy(() => import('./components/Incidencias/IncidenciasIndex'));

const PenalizacionesIndex = lazy(() => import('./components/Penalizaciones/PenalizacionesList'));
const PassengerPenaltiesIndex = lazy(() => import('./components/Penalizaciones/PassengerPenalties.js'));
const DriverPenalty = lazy(() => import('./components/Penalizaciones/DriverPenalty'));

const PlansIndex = lazy(() => import('./components/Plans/PlansList'));
const PlanView = lazy(() => import('./components/Plans/PlanView'));

const ListServices = lazy(() => import('./components/Services/Services'));
const ServiceView = lazy(() => import('./components/Services/Service'));


const SettingsList = lazy(() => import('./components/Settings/SettingsList'));
const SettingsForm = lazy(() => import('./components/Settings/SettingForm'));

//Lista de paginas que van fuera del layout wrapper
const listofPages = [
    '/login'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };


    const animationName = 'rag-fadeIn'
    // console.log("refdconeriod wmdcsk", listofPages.indexOf(location.pathname))
    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div className="pages-container">
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route path="/login" component={waitFor(Login)} />
                                    {/* <Route exact path="/" component={waitFor(Home)} /> */}
                                    {/* <Route exact path="/banners" component={waitFor(Banners)} /> */}
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <ProtectedRoute exact path="/" component={waitFor(Home)} />
                                    <ProtectedRoute exact path="/admins" component={waitFor(Administrators)} />
                                    <ProtectedRoute exact path="/admins/new" component={waitFor(AdministratorForm)} />
                                    <ProtectedRoute exact path="/admins/:id" component={waitFor(AdministratorForm)} />

                                    <ProtectedRoute exact path="/notifications" component={waitFor(Notifications)} />
                                    <ProtectedRoute exact path="/notifications/new" component={waitFor(NotificationForm)} />
                                    <ProtectedRoute exact path="/notifications/:id" component={waitFor(NotificationForm)} />

                                    <ProtectedRoute exact path="/clients" component={waitFor(Clients)} />
                                    <ProtectedRoute exact path="/clients/:id" component={waitFor(ClientView)} />

                                    <ProtectedRoute exact path="/drivers" component={waitFor(Drivers)} />
                                    <ProtectedRoute exact path="/drivers/:id" component={waitFor(DriverView)} />

                                    <ProtectedRoute exact path="/drivers-validate" component={waitFor(DriversnoVerify)} />
                                    <ProtectedRoute exact path="/drivers-validated" component={waitFor(DriversVerified)} />
                                    <ProtectedRoute exact path="/drivers-validate/:id" component={waitFor(ValidateDriver)} />  

                                     <ProtectedRoute exact path="/ventas" component={waitFor(Ventas)} />
                                    <ProtectedRoute exact path="/ventas/:id" component={waitFor(VentasView)} />       

                                     <ProtectedRoute exact path="/trips" component={waitFor(Trips)} />
                                    <ProtectedRoute exact path="/trips/:id" component={waitFor(TripView)} />       

                                    <ProtectedRoute exact path="/emails" component={waitFor(Emails)} />
                                    <ProtectedRoute exact path="/emails/new" component={waitFor(EmailForm)} />     
                                    <ProtectedRoute exact path="/emails/:id" component={waitFor(EmailForm)} /> 

                                    <ProtectedRoute exact path="/settings" component={waitFor(SettingsList)} />    
                                    <ProtectedRoute exact path="/settings/:id" component={waitFor(SettingsForm)} /> 

                                    <ProtectedRoute exact path="/planes" component={waitFor(PlansIndex)} />
                                    <ProtectedRoute exact path="/planes/:id" component={waitFor(PlanView)} /> 

                                    <ProtectedRoute exact path="/payments" component={waitFor(Payments)} />
                                    <ProtectedRoute exact path="/payments/:id" component={waitFor(PaymentView)} />

                                    <ProtectedRoute exact path="/services" component={waitFor(ListServices)} />
                                    <ProtectedRoute exact path="/services/:id" component={waitFor(ServiceView)} />

                                    <ProtectedRoute exact path="/late-payments" component={waitFor(LatePayments)} />
                                    <ProtectedRoute exact path="/payments-paid" component={waitFor(PaymentsPaid)} />
                                    <ProtectedRoute exact path="/past-due-payments" component={waitFor(PastDuePayments)} />
                                    <ProtectedRoute exact path="/pending-payments" component={waitFor(PendingPayments)} />

                                    <ProtectedRoute exact path="/incidencias" component={waitFor(IncidenciasIndex)} /> 

                                    <ProtectedRoute exact path="/driver-penalties" component={waitFor(PenalizacionesIndex)} />
                                    <ProtectedRoute exact path="/driver-penalties:id" component={waitFor(DriverPenalty)} />

                                    <ProtectedRoute exact path="/passenger-penalties" component={waitFor(PassengerPenaltiesIndex)} />

                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                <ToastContainer />
            </Base>
        )
    }
}

export default withRouter(Routes);
