import React, { useState, useEffect } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Button, Container } from 'reactstrap';
import ContentWrapper from "../Layout/ContentWrapper";
import { getPlansList } from '../../services/Services';
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';

const PlansList = (props) => {
    const [data, setData] = useState([]);
    
    async function fetchInformation() {
        await getPlansList().then((result) => {
            let sebo = [...result.data];
            sebo.map((item, index) => {
                sebo[index].itemDate = moment(item.fecha_de_creacion).format('lll');
            })
            setData(sebo);
        });
    }

    const EditSection = props => (
        <div className="text-center py-2">
            <Button className="ml-1" onClick={() => editInfo(props)}> <i className="far fa-eye"></i> </Button>
        </div>
    )

    const editInfo = ({ value }) => {
        props.history.push(`/planes/${value}`)
    }

    const rowGetter = (i) => data[i]

    const columns = [
        { key: 'itemDate', name: 'Fecha' },
        { key: 'type', name: 'Tipo' },
        { key: 'precio', name: 'Costo'},
        { key: 'id', name: 'Edición', formatter: EditSection, width: 150 }
    ];

    useEffect(() => {
        fetchInformation();
    }, [])

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div className="row">
                    <div className="col-md-9">Servicios</div>
                </div>

            </div>

            <Container fluid className="shadow">
                <ReactDataGrid
                    columns={columns}
                    rowGetter={rowGetter}
                    rowsCount={data.length}
                    rowHeight={60}
                    minHeight={700} />
            </Container>
        </ContentWrapper>
    )
}

export default PlansList;